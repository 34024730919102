import { fetch, post } from '@/utils/axios'

export default {
    // 首页产品列表
    getHomeProduct: (params) => {
        return fetch('/Product/home', params)
    },
    // 交易产品列表
    getProductTrade: (params) => {
        return fetch('/Product/trade', params)
    },
    // 交易产品详情
    getProductDetail: (params) => {
        return fetch('/Product/detail', params)
    },
    // 获取产品100条每秒价格数据
    get1S: (params) => {
        return fetch('/Product/secondPrices', params)
    },
    // 获取产品100条每分钟数据
    get1M: (params) => {
        return fetch('/Product/oneMinutePrices', params)
    },
    // 获取100条每五分钟价格变动
    get5M: (params) => {
        return fetch('/Product/FiveMinutePrices', params)
    },
    // 获取100条每十五分钟价格变动
    get15M: (params) => {
        return fetch('/Product/FifteenMinutePrices', params)
    },
    // 获取100条每三十分钟价格变动
    get30M: (params) => {
        return fetch('/Product/ThirtyMinutePrices', params)
    },
    // 获取100条每小时价格变动
    get1Hour: (params) => {
        return fetch('/Product/OneHourPrices', params)
    },
    // 获取100条每4小时价格变动
    get4Hour: (params) => {
        return fetch('/Product/FourHourPrices', params)
    },
    // 获取100条每日价格变动
    get1Day: (params) => {
        return fetch('/Product/DayPrices', params)
    },
    // 产品成交盘数据
    GetTradeSecond: (params) => {
        return fetch('/Product/GetTradeSecond', params)
    },
    // 托管产品列表
    GetTsProducts: (params) => {
        return fetch('/TsProduct/GetTsProducts', params)
    },
    // 产品利率列表
    GetInterestRates: (params) => {
        return fetch('/TsProduct/GetInterestRates', params)
    },
}