<template>
  <div class="container">
    <PublicHeader :totalName="proInfo.Name" />
    <!-- 产品 名称-->
    <div class="proName">
      <img src="@/assets/img/exchange.png" width="17" @click="showProList = true" />
      <div class="name">{{ proInfo.Name }}</div>
    </div>
    <!-- 产品数据 -->
    <div class="proNum">
      <div class="left">
        <div class="num">{{ $t('etf').high }}：{{ proInfo.High }}</div>
        <div class="num">{{ $t('etf').low }}：{{ proInfo.Low }}</div>
        <div class="num">{{ $t('etf').vol }}：{{ proInfo.Vol }}</div>
      </div>
      <div class="right">
        <div class="price">{{ proInfo.LastPrice }}</div>
        <div class="flu" :class="proInfo.IsRise ? 'red' : 'green'">{{ proInfo.Fluctuation }}%</div>
      </div>
    </div>
    <!-- k线 -->
    <div class="kBox">
      <div class="data-mode-box">
        <div v-for="(dItem, dIndex) in dataModeList" :key="dIndex"
          :class="{ dataMoeActive: dataMoeActiveIndex === dIndex }" @click="changedataMode(dIndex)">
          {{ dItem }}
        </div>
      </div>
      <EcharK :width="width" :Kdata="Kdata" :Kname="Kname" :KpriceDta="KpriceDta" :height="height" id="b" />
    </div>
    <div class="line"></div>
    <!-- 切换 -->
    <div class="tabs">
      <div class="item" :class="{ active: tabActive === 0 }" @click="tabActive = 0">{{ $t('etf').markTrades }}</div>
      <div class="item" :class="{ active: tabActive === 1 }" @click="tabActive = 1">{{ $t('etf').newTrades }}</div>
      <div class="item" :class="{ active: tabActive === 2 }" @click="tabActive = 2">{{ $t('etf').coinInfo }}</div>
    </div>
    <!-- 买卖盘 -->
    <div class="buysell" v-if="tabActive == 0">
      <div class="buy">
        <div class="title">{{ $t('etf').num }} <span>{{ $t('etf').buyPrice }}</span></div>
        <div class="item" v-for="(dItem, dIndex) in buyList" :key="`buy${dIndex}`">{{ dItem.amount }} <span>{{
          dItem.price
            }}</span></div>
      </div>
      <div class="sell">
        <div class="title">{{ $t('etf').num }} <span>{{ $t('etf').sellPrice }}</span></div>
        <div class="item" v-for="(dItem, dIndex) in sellList" :key="`sell${dIndex}`">{{ dItem.amount }} <span>{{
          dItem.price
            }}</span></div>
      </div>
    </div>
    <!-- 最新成交 -->
    <div class="transactions" v-if="tabActive == 1">
      <div class="title">{{ $t('etf').time }} <span>{{ $t('etf').direction }}</span><span>{{ $t('etf').price
          }}</span><span>{{ $t('etf').num }}</span></div>
      <div class="item" v-for="(dItem, dIndex) in AllList" :key="`tran${dIndex}`">
        <div class="time">{{ dItem.ts | getHourTime }}</div>
        <div class="direction" :class="dItem.direction == 'buy' ? 'green' : 'red'">{{ dItem.direction == 'buy' ?
          $t('etf').buy :
          $t('etf').sell }}</div>
        <span>{{ dItem.price }}</span><span>{{ dItem.amount }}</span>
      </div>
    </div>
    <!-- 币种信息 -->
    <div class="proinfo" v-if="tabActive == 2">
      <div class="title"><img src="@/assets/img/heard.png" width="27px" /><span>{{ proInfo.FullName }}</span></div>
      <div class="item">{{ $t('etf').IssueTotal }}<span>{{ proInfo.IssueTotal }}</span></div>
      <div class="item">{{ $t('etf').CirculateTotal }}<span>{{ proInfo.CirculateTotal }}</span></div>
      <div class="item">{{ $t('etf').IssuePrice }}<span>{{ proInfo.IssuePrice }}</span></div>
      <div class="item">{{ $t('etf').IssueTime }}<span>{{ proInfo.IssueTime | getLocalTime }}</span></div>
      <div class="item">{{ $t('etf').WhitePaperAddress }}
        <div class="cop"><span>{{ proInfo.WhitePaperAddress }}</span>
          <div class="copy" @click="onCopy(proInfo.WhitePaperAddress)">复制</div>
        </div>
      </div>
      <div class="item">{{ $t('etf').OfficialWebsiteAddress }}
        <div class="cop"><span>{{ proInfo.OfficialWebsiteAddress }}</span>
          <div class="copy" @click="onCopy(proInfo.OfficialWebsiteAddress)">复制</div>
        </div>
      </div>
      <div class="label">{{ $t('etf').Infos }}</div>
      <div class="info" v-html="proInfo.Intro"></div>
    </div>
    <!-- 按钮 -->
    <div class="btns" @click="$router.push({ name: 'Etf', query: { code: NowCode } })">
      <div class="btn1">{{ $t('etf').buyBtn }}</div>
      <div class="btn2">{{ $t('etf').sellBtn }}</div>
    </div>
    <!-- 产品列表 -->
    <van-popup v-model="showProList" closeable position="left">
      <div class="pro_list">
        <div class="name">ETF</div>
        <div class="title">{{ $t('etf').product }}<span>{{ $t('etf').newProce }}</span><span>{{ $t('etf').Fluctuation
            }}</span></div>
        <div class="item" v-for="(dItem, dIndex) in pList" :key="`pro${dIndex}`" @click="selectProduct(dItem.Code)">
          <div class="pname">{{ dItem.Name }}/USDT</div>
          <div class="price">{{ dItem.LastPrice }}</div>
          <div :class="dItem.IsRise ? 'red' : 'green'">{{ dItem.Fluctuation }}%</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import productApi from '@/api/product'
import { Token } from '@/utils/mm'
import EcharK from '@/components/echarK'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader,
    EcharK,
  },
  data() {
    return {
      showProList: false,
      pList: [],
      NowCode: 'BTC',
      tabActive: 0,
      proInfo: {},
      dataModeList: [
        '1' + this.$t('etf').smallMin,
        '5' + this.$t('etf').smallMin,
        '15' + this.$t('etf').smallMin,
        '30' + this.$t('etf').smallMin,
        '1' + this.$t('etf').hour,
        '4' + this.$t('etf').hour,
        '1' + this.$t('etf').dayMin,
      ],
      dataMoeActiveIndex: 0,
      Kdata: [],
      KpriceDta: {},
      Kname: '',
      buyList: [{}],
      sellList: [{}],
      AllList: [],
      width: '100%',
      height: '82vw',
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    if (this.$route.query.code) {
      this.NowCode = this.$route.query.code
    }
    this.changedataMode(0)
    this.signalRStart()
    this.goDetail()
    this.getProductTrade()
  },
  methods: {
    // 选择产品
    selectProduct(code) {
      this.showProList = false
      this.NowCode = code
      this.goDetail()
      this.getProductTrade()
      this.GetTradeSecond()
    },
    // 获取交易产品列表
    async getProductTrade() {
      const res = await productApi.getProductTrade()
      this.pList = res.Data
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
    //产品详情
    async goDetail() {
      const res = await productApi.getProductDetail({ code: this.NowCode })
      this.proInfo = res.Data
    },
    //产品成交盘数据
    async GetTradeSecond() {
      const res = await productApi.GetTradeSecond({ code: this.NowCode })
      if (res.Data) {
        this.buyList = res.Data.Buy
        this.sellList = res.Data.Sell
        this.AllList = res.Data.Buy.concat(res.Data.Sell).sort((a, b) => b.ts - a.ts).slice(0, 10)
      }
    },
    // 选择分时图
    changedataMode(index) {
      this.dataMoeActiveIndex = index
      this.getKData(index)
    },
    //获取k线数据
    async getKData(mode) {
      let res = []
      switch (mode) {
        case 0:
          res = await productApi.get1M({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 1:
          res = await productApi.get5M({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 2:
          res = await productApi.get15M({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 3:
          res = await productApi.get30M({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 4:
          res = await productApi.get1Hour({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 5:
          res = await productApi.get4Hour({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
        case 6:
          res = await productApi.get1Day({
            code: this.NowCode
          })
          this.lastPrice = res.Data[res.Data.length - 1]
          break
      }
      this.Kname = this.dataModeList[mode]
      this.Kdata = res.Data
    },
    //推送
    signalRStart() {
      let that = this
      const connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      //买卖盘
      connection.on('secondTrades', function (res) {
        res.forEach((item) => {
          if (item.Code === that.proInfo.Code) {
            that.buyList = item.Buy
            that.sellList = item.Sell
            that.AllList = item.Buy.concat(item.Sell).sort((a, b) => b.ts - a.ts).slice(0, 10)
          }
        })
      })
      //最新价格
      connection.on('secondPrices', function (res) {
        res.forEach((item) => {
          //产品列表
          that.pList.forEach((sItem) => {
            if (item.Code === sItem.Code && item.Price > 0) {
              sItem.LastPrice = item.Price
              sItem.Fluctuation = item.Fluctuation
            }
          })
          //产品详情
          if (item.Code === that.proInfo.Code) {
            that.proInfo.LastPrice = item.Price
            that.proInfo.Fluctuation = item.Fluctuation
            that.proInfo.High = item.High
            that.proInfo.Low = item.Low
            that.proInfo.Vol = item.Vol
            that.proInfo.IsRise = item.IsRise
          }
          //推送数据加入K线图实时价格
          if (item.Code === that.NowCode) {
            that.KpriceDta = {}
            that.KpriceDta.price = item.Price
            that.KpriceDta.color = item.IsRise ? '#EB4D5C' : '#53B987'
          }
        })
      })
    },

  }
}
</script>

<style lang="less" scoped>
.red {
  color: #C92B2E !important;
}

.green {
  color: #35B56D !important;
}

.container {
  min-height: 100vh;
  font-size: 14px;
  padding-top: 65px;
  background: #ffffff;

  & /deep/ .van-popup--left {
    top: 65px;
    height: calc(100vh - 50px) !important;
    width: 65%;
    transform: translate3d(0, 0, 0);
    border-radius: 0 10px 0 0;
  }

  & /deep/ .van-popup__close-icon {
    color: #000000;
  }

  .proName {
    display: flex;
    align-items: center;
    margin: 0 13px;

    img {
      margin-bottom: 3px;
    }

    .name {
      margin-left: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
  }

  .proNum {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    background: #F8F8F8;

    .left {
      .num {
        padding: 3px 0;
        font-size: 12px;
        color: #333333;
      }
    }

    .right {
      .price {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      .flu {
        margin-top: 10px;
        font-weight: 800;
        font-size: 18px;
      }

    }

  }

  .kBox {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;

    .data-mode-box {
      margin: 15px 13px;
      display: flex;
      background: #ffffff;

      div {
        flex: 1;
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dataMoeActive {
        font-weight: bold;
        color: #C92B2E;
      }
    }
  }

  .line {
    height: 4px;
    background: #F1F1F1;
  }

  .tabs {
    display: flex;
    align-items: center;
    padding: 13px;

    .item {
      margin-right: 30px;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .active {
      color: #C92B2E;
    }
  }

  .buysell {
    padding: 0 13px 80px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buy {
      width: 50%;

      .title {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #999999;
      }

      .item {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        color: #35B56D;
        border-bottom: 1px solid #E3E3E3;

        span {
          font-weight: 500;
          font-size: 13px;
          color: #999999;
        }
      }
    }

    .sell {
      width: 50%;
      margin-left: 10px;

      .title {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #999999;
      }

      .item {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        color: #C92B2E;
        border-bottom: 1px solid #E3E3E3;

        span {
          font-weight: 500;
          font-size: 13px;
          color: #999999;
        }
      }
    }

  }

  .transactions {
    padding: 0 13px 80px 13px;

    .title {
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #999999;
    }

    .item {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #999999;
      border-bottom: 1px solid #E3E3E3;

      .time {
        width: 30%;
      }

      span {
        width: 30%;
        text-align: right;
      }

      .direction {
        text-align: center;
        width: 10%;
      }
    }
  }

  .proinfo {
    padding: 10px 13px 80px 13px;

    .title {
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      color: #333333;

      span {
        margin-left: 10px;
      }
    }

    .item {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #999999;

      span {
        color: #333333;
      }

      .cop {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copy {
          margin-left: 5px;
          border-left: 1px solid #D8D8D8;
          height: 15px;
          display: flex;
          align-items: center;
          padding-left: 5px;
          font-size: 13px;
          color: #3485FF;
        }
      }

    }

    .label {
      padding: 10px 0;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .info {
      font-size: 13px;
      color: #999999;
      line-height: 22px;
    }
  }

  .btns {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 13px;
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0 -1px 1px 1px rgba(0, 0, 0, 0.05);

    .btn1 {
      width: 170px;
      height: 40px;
      background: #309C62;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #FFFFFF;
    }

    .btn2 {
      width: 170px;
      height: 40px;
      background: #C92B2E;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #FFFFFF;
    }
  }

  .t_btn {
    margin: 15px 13px 10px 13px;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
    }

    .item {
      width: 171px;
      height: 47px;
      background: linear-gradient(317deg, #FF8020 0%, #FFA560 100%);
      border-radius: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
    }

    .item2 {
      width: 171px;
      height: 47px;
      background: linear-gradient(308deg, #2E2E2E 0%, #414141 100%);
      border-radius: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
    }

  }

  .pro_list {
    margin: 0 13px;
    padding: 20px 0;
    background: #fff;
    border-radius: 5px;

    .name {
      font-weight: bold;
      font-size: 17px;
      color: #333333;
    }

    .title {
      margin: 20px 0 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 13px;
        color: #999999;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E3E3E3;
      font-size: 13px;

      .pname {
        font-weight: bold;
        color: #333333;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        color: #999999;
      }
    }
  }
}

::v-deep .van-dialog {
  background-color: transparent;
}
</style>
