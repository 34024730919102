<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLocalTime } from '@/utils/validate'
export default {
  props: {
    id: {
      type: String,
      default: 'a'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    Kname: {
      type: String,
      default: ''
    },
    dayMode: {
      type: Boolean,
      default: false
    },
    KpriceDta: {
      type: Object,
      default() {
        return {}
      }
    },
    Kdata: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      start: 70,
      end: 100
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  watch: {
    Kdata(newValue) {
      if (newValue.length) {
        this.drawLine()
      }
    },
    KpriceDta(newValue) {
      if (newValue) {
        this.drawLine()
      }
    },
  },
  methods: {
    drawLine() {
      //重载 滑块位置不变
      var that = this
      that.myChart.on('dataZoom', function (event) {
        if (event.batch) {
          that.start = event.batch[0].start
          that.end = event.batch[0].end
        }
      })
      this.myChart.setOption(this.setKOption(this.Kdata, this))
    },

    setKOption(Kdata, that) {
      var upColor = '#EB4D5C' //涨
      var upBorderColor = '#EB4D5C'
      var downColor = '#53B987'
      var downBorderColor = '#53B987'
      // 数据意义：开盘(open)，收盘(close)，最低(lowest)，最高(highest)

      var data0 = splitData1()

      function splitData1() {
        var categoryData = []
        var values = []
        var volumes = []
        for (var i = 0; i < Kdata.length; i++) {
          let time = ''
          const CreateTime = getLocalTime(Kdata[i].CreateTime)
          if (that.dayMode) {
            time = CreateTime.split(' ')[0]
          } else {
            time = CreateTime.split(' ')[1]
            time = time.substr(0, 5)
          }
          categoryData.push(time)
          let arr = [
            Kdata[i].Open,
            Kdata[i].Close,
            Kdata[i].Low,
            Kdata[i].High,
            Kdata[i].Volume
          ]
          values.push(arr)
          volumes.push([
            i,
            Kdata[i].Volume,
            Kdata[i].Open > Kdata[i].Close ? 1 : -1
          ])
        }
        return {
          categoryData: categoryData,
          values: values,
          volumes: volumes
        }
      }
      function calculateMA(dayCount) {
        var result = []
        for (var i = 0, len = data0.values.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-')
            continue
          }
          var sum = 0
          for (var j = 0; j < dayCount; j++) {
            sum += +data0.values[i - j][1]
          }
          result.push((sum / dayCount).toFixed(2))
        }
        return result
      }

      function calculateMAV(dayCount) {
        var result = []
        for (var i = 0, len = data0.values.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-')
            continue
          }
          var sum = 0
          for (var j = 0; j < dayCount; j++) {
            sum += +data0.values[i - j][4]
          }
          result.push((sum / dayCount).toFixed(2))
        }
        return result
      }

      return {
        legend: {
          data: ['MA5', 'MA10', 'MA30']
        },
        visualMap: {
          show: false,
          seriesIndex: 4,
          dimension: 2,
          pieces: [
            {
              value: 1,
              color: downColor
            },
            {
              value: -1,
              color: upColor
            }
          ]
        },
        grid: [
          {
            show: false,
            left: '5%',
            right: '17%',
            // bottom: '10%',
            top: '5%'
          },
          {
            left: '5%',
            right: '17%',
            top: '73%',
            height: '20%'
          }
        ],
        backgroundColor: '#ffffff',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: data0.categoryData,
            scale: true,
            boundaryGap: false,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: '#bdb9b9',
                width: 1,
                type: 'solid'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#e9e9e9'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: { show: true },
            axisLabel: { show: false },
            min: 'dataMin',
            max: 'dataMax'
          },
          {
            type: 'category',
            gridIndex: 1,
            data: data0.categoryData,
            scale: true,
            boundaryGap: false,
            axisLine: { onZero: false, show: true },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#e9e9e9'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: { show: true },
            axisLabel: { show: true },
            min: 'dataMin',
            max: 'dataMax'
          }
        ],
        yAxis: [
          {
            scale: true,
            boundaryGap: [0.1, 0.1],
            position: 'right',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#e9e9e9'],
                width: 1,
                type: 'dashed'
              }
            }
          },
          {
            scale: true,
            boundaryGap: [0.1, 0.1],
            position: 'right',
            gridIndex: 1,
            splitNumber: 2,
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#e9e9e9'],
                width: 1,
                type: 'dashed'
              }
            }
          }
        ],
        dataZoom: [
          {
            filterMode: 'filter',
            type: 'inside',
            start: that.start,
            end: that.end
          },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'inside',
            top: '85%',
            start: that.start,
            end: that.end
          }
        ],
        markLine: {
          symbol: ['none', 'none'], //去掉箭头
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid',
                color: {
                  //设置渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'red ' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'blue' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              label: {
                show: true,
                position: 'middle'
              }
            }
          }
        },
        series: [
          {
            name: that.Kname,
            type: 'candlestick',
            data: data0.values,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            },
            markPoint: {//最高最低
              symbolSize: 20,
              itemStyle: {
                normal: {
                  color: 'rgba(0,0,0,0)'
                }
              },
              label: {
                show: true,
                color: '#6c6c6c'
              },
              data: [
                {
                  name: 'highest value',
                  type: 'max',
                  valueDim: 'highest'
                },
                {
                  name: 'lowest value',
                  type: 'min',
                  valueDim: 'lowest'
                }
              ]
            },
            markLine: {
              symbol: ['none', 'none'],
              data: [
                {
                  silent: false,
                  yAxis: parseFloat(that.KpriceDta.price).toFixed(10), // 警戒线位置，这个赋值为纵轴
                  label: {
                    position: 'end', // 文字位置
                    color: 'white', // 文字颜色
                    show: true,
                    backgroundColor: that.KpriceDta.color,
                    borderDashOffset: 0,
                    padding: [3, 3, 0, 3],
                    // borderRadius: [3, 3, 3, 3]
                  },
                  lineStyle: { color: that.KpriceDta.color } // 样式： 颜色
                }
              ]
            }
          },
          {
            name: 'MA5',
            symbol: 'none',
            type: 'line',
            data: calculateMA(5),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA10',
            symbol: 'none',
            type: 'line',
            data: calculateMA(10),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA30',
            symbol: 'none',
            type: 'line',
            data: calculateMA(30),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'Volume',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data0.volumes
          },
          {
            name: 'MA5',
            symbol: 'none',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: calculateMAV(5),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA10',
            symbol: 'none',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: calculateMAV(10),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA30',
            symbol: 'none',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: calculateMAV(30),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
    })
  }
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
